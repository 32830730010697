import {Theme} from '@mui/material';
import JobNotFoundHost from '../components/JobNotFoundHost';
import ViewHost from '../components/ViewHost';

const routes = (theme: Theme) => {
    return [
        {
            path: '/:id',
            key: 'tracking-app-panel',
            element: <ViewHost theme={theme} />,
        },
        {
            path: '*',
            key: 'job-not-found',
            element: <JobNotFoundHost theme={theme} />,
        },
    ];
};

export default routes;
