const pl = {
    app: {
        title: 'Śledzenie zamówienia',
        companyTitle: 'PaliPali',
        errorPages: {
            401: {
                title: '401 - Nieautoryzowany',
                description: 'Nie masz uprawnień do przeglądania tej strony.',
                buttons: {
                    backToHome: 'Powrót do strony głównej',
                },
            },
            404: {
                title: '404 - Nie znaleziono strony',
                description: 'Strona, której szukasz nie istnieje.',
                buttons: {
                    backToHome: 'Powrót do strony głównej',
                },
            },
            500: {
                title: '500 - Błąd serwera',
                description: 'Coś poszło nie tak. Spróbuj ponownie później.',
                buttons: {
                    backToHome: 'Powrót do strony głównej',
                },
            },
        },
        timeComponent: {
            estimatedTime: ' (przewidywany czas)',
            courierArriving: 'Kurier niebawem będzie na miejscu.',
            processAt: 'Zlecenie zostanie opublikowane w dniu:',
            timer: {
                hours: 'godz.',
                minutes: 'min.',
            },
        },
        exception: {
            invalid_identifier: 'Podany identyfikator jest nieprawidłowy.',
        },
    },
    noJob: {
        title: 'Zlecenie nie istnieje',
        description: 'Zlecenie o wskazanym numerze nie istnieje.',
    },
    jobInactive: {
        title: 'Zlecenie nie jest już aktywne',
        description: 'Zlecenie zostało zrealizowane lub anulowane.',
    },
    button: {
        visitPageLabel: {
            visit: 'Odwiedź nas na',
            www: 'www.palipali.pl',
        },
    },
    jobInfoCard: {
        purchaser: 'Zlecający',
        courier: 'Kurier',
        sender: 'Punkt nadania',
        innerOrderNumber: 'Numer wewnętrzny zamówienia',
        additionalInfo: 'Dodatkowe informacje',
        estimatedTime: ' (przewidywany czas)',
        courierArriving: 'Kurier niebawem będzie na miejscu.',
        processAt: 'Zlecenie zostanie opublikowane w dniu:',
        timer: {
            hours: 'godz.',
            minutes: 'min.',
        },
    },
    teams: {
        teamView: {
            teamDetails: {
                street: 'ul.',
            },
        },
    },
    alerts: {
        warning: 'Uwaga!',
        error: 'Błąd!',
        success: 'Sukces!',
        info: 'Informacja',
        tryAgain: 'Spróbuj ponownie',
        unknown: 'Wystąpił nieznany błąd.',
        accessDenied: 'Odmowa dostępu',
        notFound: 'Zlecenie o podanym id nie istnieje.',
    },
    jobStatusDetails: {
        planned: 'Zaplanowane',
        searching: 'Szukanie',
        drive: 'W drodze',
        pickup: 'Podjęcie przesyłki',
        delivery: 'Wydanie przesyłki',
        delivered: 'Zrealizowane',
        cancelledPurchaser: 'Anulowane',
        cancelledCourier: 'Anulowane przez Kuriera',
        problemPurchaser: 'Zgłoszone',
        problemCourier: 'Zgłoszone przez Kuriera',
        deliveryDrive: 'W doręczeniu',
        failed: 'Niepowodzenie',
    },
};

export default pl;
