import React, {useEffect} from 'react';
import {Box, Theme, ThemeProvider} from '@mui/material';
import {IAnonymousJobDetailsOutput, Loader} from 'palipali-panel-common-web';
import TrackingAppHeader from '../TrackingAppHeader';
import JobTrackingCard from '../JobTrackingCard';
import JobPlaceholderCard from '../JobPlaceholderCard';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {getAnonymousJob} from '../../store/reducers/anonymousJobSlice';
import {anonymousJobSelector, isJobLoadingSelector} from '../../store/selectors/jobSelector';

interface IPanelHostProps {
    theme: Theme;
}

const statusesToDisplay: string[] = ['pickup', 'pickup:drive', 'delivery:drive', 'delivery', 'delivered'];

const ViewHost: React.FC<IPanelHostProps> = (props: IPanelHostProps) => {
    const dispatch = useDispatch(),
        {id} = useParams(),
        anonymousJob: IAnonymousJobDetailsOutput | null = useSelector(anonymousJobSelector),
        isLoading: boolean = useSelector(isJobLoadingSelector);

    useEffect(() => {
        if (id) dispatch(getAnonymousJob({id: id}));
    }, [id]);

    const renderCard = (job: IAnonymousJobDetailsOutput | null) => {
        if (isLoading) return;

        if (!job || (job && job.deliveryStatus === 'planned') || (job && job.deliveryStatus === 'failed'))
            return <JobPlaceholderCard labelKey={'noJob'} />;

        if (job && statusesToDisplay.indexOf(job.deliveryStatus) > -1 && !job.isCompleted) return <JobTrackingCard job={job} />;

        return <JobPlaceholderCard labelKey={'jobInactive'} />;
    };
    return (
        <ThemeProvider key="provider-wrapper" theme={props.theme}>
            <Box component="main" className="tracking-app-host">
                <Box className="tracking-app-content">
                    <TrackingAppHeader />
                    {renderCard(anonymousJob)}
                </Box>
                <Loader showLoader={isLoading} />
            </Box>
        </ThemeProvider>
    );
};
export default ViewHost;
