import {PayloadAction} from '@reduxjs/toolkit';
import {IAnonymousJobDetailsOutput, createBaseReducerSlice} from 'palipali-panel-common-web';

export interface AnonymousJobState {
    job: IAnonymousJobDetailsOutput | null;
    isLoading: boolean;
}

export interface ISetAnonymousJob {
    job: IAnonymousJobDetailsOutput;
}

const initialState: AnonymousJobState = {
    job: null,
    isLoading: false,
};

const AnonymousJobSlice = createBaseReducerSlice({
    name: 'anonymousJob',
    initialState: initialState,
    reducers: {
        getAnonymousJob: (state: AnonymousJobState) => {
            return {
                ...state,
                isLoading: true,
            };
        },

        setLoading: (state: AnonymousJobState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },

        setAnonymousJob: {
            reducer: (state: AnonymousJobState, action: PayloadAction<ISetAnonymousJob>) => {
                return {
                    ...state,
                    isLoading: false,
                    job: action.payload.job,
                };
            },
            prepare: (job: IAnonymousJobDetailsOutput) => {
                return {
                    payload: {
                        job: job,
                    },
                };
            },
        },

        returnToInitialJobState: () => {
            return {...initialState};
        },
    },
});

export const {getAnonymousJob, setLoading, setAnonymousJob, returnToInitialJobState} = AnonymousJobSlice.actions;

export default AnonymousJobSlice.reducer;
