import {
    DetailsSuccessActionsFunction,
    IAnonymousJobDetailsOutput,
    createFetchDetailsEpic,
    getAnonymousJobAPI,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {getAnonymousJob, setAnonymousJob, setLoading} from '../reducers/anonymousJobSlice';

const aonymousJobSuccessActions: DetailsSuccessActionsFunction<IAnonymousJobDetailsOutput> = (job: IAnonymousJobDetailsOutput) => {
    return [setLoading(false), setAnonymousJob(job)];
};

const errorActions = (): any[] => {
    return [setLoading(false)];
};

const getAnonymousJobEpic = createFetchDetailsEpic<IAnonymousJobDetailsOutput>(
    getAnonymousJobAPI,
    aonymousJobSuccessActions,
    errorActions,
    getAnonymousJob().type
);

const jobEpic = combineEpics(getAnonymousJobEpic);

export default jobEpic;
