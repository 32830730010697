import React from 'react';
import {Provider} from 'react-redux';
import store, {history, persistor} from './store';
import {HistoryRouter as Router} from 'redux-first-history/rr6';
import routes from './routes/routes';
import {SettingsConsumer, SettingsProvider, ThemeRouterHost, createTheme, ColorPreset, Toast} from 'palipali-panel-common-web';
import {PersistGate} from 'redux-persist/lib/integration/react';
const App: React.FC = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                    <SettingsProvider>
                        <SettingsConsumer>
                            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                            {(settings: any) => {
                                const theme = createTheme({
                                    colorPreset: 'purchaser' as ColorPreset,
                                    contrast: settings.contrast,
                                    direction: settings.direction,
                                    paletteMode: settings.paletteMode,
                                    responsiveFontSizes: settings.responsiveFontSizes,
                                });

                                return <ThemeRouterHost theme={theme} settings={settings} routes={routes} colorPreset={'purchaser'} />;
                            }}
                        </SettingsConsumer>
                        <Toast />
                    </SettingsProvider>
                </Router>
            </PersistGate>
        </Provider>
    );
};

export default App;
