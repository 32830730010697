import L from 'leaflet';

export const carMarkerIconDefault = L.icon({
        iconUrl: require('./car-marker-icon-default.png'),
        iconSize: [24, 50],
        iconAnchor: [22, 50],
        popupAnchor: [-3, -76],
    }),
    receiverMarkerIcon = L.icon({
        iconUrl: require('./receiver-marker-icon.png'),
        iconSize: [48, 48],
        iconAnchor: [24, 48],
        popupAnchor: [-3, -76],
    }),
    senderMarkerIcon = L.icon({
        iconUrl: require('./sender-marker-icon.png'),
        iconSize: [48, 48],
        iconAnchor: [48, 48],
        popupAnchor: [-3, -76],
    });
