import React from 'react';
import {Box, Button, Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';
import {Translation, AlertTriangle} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';

interface IJobPlaceholderCardProps {
    labelKey: string;
}

const JobPlaceholderCard: React.FC<IJobPlaceholderCardProps> = (props: IJobPlaceholderCardProps) => {
    const {t} = useTranslation();

    const goToHome = () => (window.location.href = `${process.env.REACT_APP_HOME_URL}`);

    return (
        <Card className="job-inactive-card small">
            <CardHeader title={t(`${props.labelKey}.title`)} />
            <Divider />
            <CardContent>
                <Box className="card-content">
                    <AlertTriangle />
                    <Typography variant="body1" component="p" color="text.primary" sx={{textAlign: 'center'}}>
                        <Translation text={`${props.labelKey}.description`} />
                    </Typography>
                </Box>
                <Button variant="contained" color="primary" size="large" onClick={() => goToHome()}>
                    <span className="mobile-hide">
                        <Translation text="button.visitPageLabel.visit" />
                    </span>
                    <span>
                        <Translation text="button.visitPageLabel.www" />
                    </span>
                </Button>
            </CardContent>
        </Card>
    );
};
export default JobPlaceholderCard;
