import {Grid} from '@mui/material';
import {IAnonymousJobDetailsOutput} from 'palipali-panel-common-web';
import React from 'react';
import {Card} from '@mui/material';
import TrackingViewDetails from './TrackingViewDetails';
import TrackingViewMap from './TrackingViewMap';

interface IJobTrackingCardProps {
    job: IAnonymousJobDetailsOutput | null;
}

const JobTrackingCard: React.FC<IJobTrackingCardProps> = (props: IJobTrackingCardProps) => {
    return (
        <Card className="tracking-view-card">
            <Grid container>
                <Grid item xs={12} md={6} className="job-info-container">
                    {props.job && <TrackingViewDetails job={props.job} />}
                </Grid>
                <Grid item xs={12} md={6} className="map-container">
                    {props.job && <TrackingViewMap job={props.job} />}
                </Grid>
            </Grid>
        </Card>
    );
};
export default JobTrackingCard;
