import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {AnonymousJobState} from '../reducers/anonymousJobSlice';

export const selectJobSlice = (state: RootState) => {
    return state.anonymousJob;
};

export const anonymousJobSelector = createSelector([selectJobSlice], (state: AnonymousJobState) => state.job);
export const isJobLoadingSelector = createSelector([selectJobSlice], (state: AnonymousJobState) => state.isLoading);
export const jobIdSelector = createSelector([selectJobSlice], (state: AnonymousJobState) => state.job?.id);
