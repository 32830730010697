import React, {useMemo, useState} from 'react';
import {Box, Divider, Stack, Typography, useTheme} from '@mui/material';
import {
    IAnonymousJobDetailsOutput,
    LocationPointBoxType,
    LocationPointType,
    JobViewHeader,
    LocationPointBox,
    JobViewDetailsListButton,
    JobViewDetailsListItem,
    JobViewAdditionalInfo,
    IPurchaserCurrentJobDetailsOutput,
    PhoneComponent,
    IPhoneOutput,
    JobStatus,
    Translation,
    TimeComponent,
} from 'palipali-panel-common-web';
import {ColorFormat, CountdownCircleTimer} from 'react-countdown-circle-timer';
import TimeValueDisplaySpan, {CircleTimerType} from './TimeValueDisplaySpan';
import moment from 'moment';

interface ITrackingViewDetailsProps {
    job: IAnonymousJobDetailsOutput;
}
const TrackingViewDetails: React.FC<ITrackingViewDetailsProps> = (props: ITrackingViewDetailsProps) => {
    if (!props.job) return <span />;

    const theme = useTheme();
    const [countNegative, setCountNegative] = useState<boolean>(false);
    const [displayInfo, setDisplayInfo] = useState<boolean>(false);
    const onButtonClick = (phone: IPhoneOutput | null) => {
        if (!phone) return;
        window.location.href = `tel:${phone.country}${phone.phone}`;
    };
    const job: IPurchaserCurrentJobDetailsOutput = props.job as IPurchaserCurrentJobDetailsOutput;

    const renderTimer = () => {
        if (!job || job.deliveryStatus === JobStatus.PLANNED || job.deliveryStatus === JobStatus.COMPLETED) return;

        return (
            <Box mt={2} width={'100%'} position={'relative'}>
                <Divider />
                <Stack className="job-view-circular-progress-wrapper">
                    <Box bgcolor={theme.palette.mode === 'dark' ? 'neutral.900' : '#FFFF'}>
                        <CountdownCircleTimer
                            size={46}
                            onComplete={handleOnTimerEnd}
                            strokeWidth={2}
                            trailColor={
                                countNegative
                                    ? (theme.palette.error.main as ColorFormat)
                                    : theme.palette.mode === 'dark'
                                    ? ('neutral.800' as ColorFormat)
                                    : ('neutral.50' as ColorFormat)
                            }
                            trailStrokeWidth={2}
                            isPlaying
                            duration={getFullTimerTimeInSeconds || 0}
                            initialRemainingTime={calculateTimerTimeThatRemainedFromFullTimerTime}
                            colors={
                                countNegative ? (theme.palette.error.main as ColorFormat) : (theme.palette.primary.main as ColorFormat)
                            }>
                            {(prop) => (
                                <TimeValueDisplaySpan
                                    {...prop}
                                    jobStatus={job.deliveryStatus}
                                    countNegative={countNegative}
                                    type={timerType}
                                    negativeElapsedTime={calculateTimerTimeThatRemainedFromFullTimerTime}
                                />
                            )}
                        </CountdownCircleTimer>
                    </Box>
                </Stack>
            </Box>
        );
    };

    const timerType = useMemo(() => {
        if (job?.deliveryStatus === JobStatus.DELIVERY) {
            return CircleTimerType.MINUTES_AND_SECONDS;
        }
        return CircleTimerType.MINUTES;
    }, [job?.deliveryStatus]);

    const handleOnTimerEnd = () => {
        if (job?.deliveryStatus === JobStatus.DELIVERY) {
            return setCountNegative(true);
        }
        return setDisplayInfo(true);
    };

    const getFullTimerTimeInSeconds = useMemo(() => {
        if (!job?.estimatedDeliveryAt || !job?.courierJobStartedAt) return undefined;
        if (job?.deliveryStatus === JobStatus.DELIVERY) {
            return moment(job?.deliveryTimeExpiresAt).diff(moment(job?.deliveryStartedAt), 'seconds');
        }
        return moment(job?.estimatedDeliveryAt).diff(job?.courierJobStartedAt, 'seconds');
    }, [job?.estimatedDeliveryAt, job?.courierJobStartedAt, job?.deliveryStatus, countNegative]);

    const calculateTimerTimeThatRemainedFromFullTimerTime = useMemo(() => {
        if (!job) {
            return 0;
        }

        if (job?.deliveryStatus === JobStatus.DELIVERY && job?.deliveryTimeExpiresAt) {
            return moment(job?.deliveryTimeExpiresAt).diff(moment(), 'seconds');
        }

        return moment(job?.estimatedDeliveryAt).diff(moment(), 'seconds');
    }, [job?.estimatedDeliveryAt, job?.deliveryStatus, countNegative]);

    const renderFutureJobInfo = () => {
        if (!job || job.deliveryStatus !== JobStatus.PLANNED || !job.processAt) return;

        return (
            <Stack className="job-timer">
                {job.processAt && (
                    <>
                        <Typography className="processed-at-wrapper" color="text.secondary" variant="caption">
                            <Translation text="jobInfoCard.processAt" />
                        </Typography>
                        <Typography className="bold-text" color="text.secondary" variant="caption">
                            <TimeComponent application="purchaser" date={job.processAt} time={job.time} isSimpleTime />
                        </Typography>
                    </>
                )}
            </Stack>
        );
    };

    return (
        <Stack className="job-info">
            <Box className="job-view-header">
                <JobViewHeader job={job} />
            </Box>
            {renderTimer()}
            {displayInfo && (
                <Box display={'flex'} mt={2} justifyContent={'center'} alignItems={'center'}>
                    <Typography variant="subtitle2" color="text.secondary">
                        <Translation text="jobInfoCard.courierArriving" />
                    </Typography>
                </Box>
            )}
            {renderFutureJobInfo()}
            <div className="location-points">
                <LocationPointBox type={LocationPointBoxType.ACTIVE} locationType={LocationPointType.SENDER} job={job} />
                <LocationPointBox type={LocationPointBoxType.ACTIVE} locationType={LocationPointType.RECEIVER} job={job} />
            </div>
            <Divider />
            <Box>
                <dl className="job-details-list">
                    {job.creator.phone && (
                        <>
                            <JobViewDetailsListButton
                                label={'jobInfoCard.purchaser'}
                                value={job.creator.firstName}
                                onClick={() => onButtonClick(job.creator.phone)}
                                isPhoneBtn={true}
                            />
                            <Divider />
                        </>
                    )}

                    {job.fromLocation?.phone && (
                        <>
                            <JobViewDetailsListButton
                                label={'jobInfoCard.sender'}
                                value={<PhoneComponent phone={job.fromLocation.phone} />}
                                onClick={() => onButtonClick(job.fromLocation.phone)}
                                isPhoneBtn={true}
                            />
                            <Divider />
                        </>
                    )}

                    {job.courier?.phone && (
                        <>
                            <JobViewDetailsListButton
                                label={'jobInfoCard.courier'}
                                value={job.courier.firstName}
                                onClick={() => onButtonClick(job.courier.phone)}
                                isPhoneBtn={true}
                            />
                            <Divider />
                        </>
                    )}

                    <JobViewDetailsListItem label={'jobInfoCard.innerOrderNumber'} value={job.orderNumber} />
                    <Divider />
                </dl>
            </Box>

            <JobViewAdditionalInfo job={job} titleKey={'jobInfoCard.additionalInfo'} />
        </Stack>
    );
};

export default TrackingViewDetails;
