import {LatLngExpression} from 'leaflet';
import React from 'react';
import {MapContainer, Marker} from 'react-leaflet';
import {IAnonymousJobDetailsOutput} from 'palipali-panel-common-web';
import {carMarkerIconDefault, receiverMarkerIcon, senderMarkerIcon} from '../../../assets/images/mapIcons';
import CustomTileLayer from './CustomTileLayer';
import UpdateMapCenter from './UpdateMapCenter';

interface ITrackingViewMapProps {
    job: IAnonymousJobDetailsOutput;
}

const TrackingViewMap: React.FC<ITrackingViewMapProps> = ({job}) => {
    if (!job) return null;

    const lastCourierLocation = job.courierLocations[0],
        fromLocationLatLng: LatLngExpression = [job.fromLocation.point.latitude, job.fromLocation.point.longitude],
        toLocationLatLng: LatLngExpression = [job.toLocation.point.latitude, job.toLocation.point.longitude];
    return (
        <MapContainer
            center={
                job.courierLocations.length !== 0
                    ? [job?.courierLocations[0]?.latitude, job?.courierLocations[0]?.longitude]
                    : [52.237049, 21.017532]
            }
            scrollWheelZoom={false}
            dragging={false}
            touchZoom={false}
            maxZoom={18}
            minZoom={2}
            doubleClickZoom={false}
            zoomControl={false}>
            {job.courierLocations.length !== 0 ? (
                <UpdateMapCenter
                    latitude={fromLocationLatLng[0]}
                    longitude={fromLocationLatLng[1]}
                    additionalPosition={
                        toLocationLatLng
                            ? {
                                  latitude: toLocationLatLng[0],
                                  longitude: toLocationLatLng[1],
                              }
                            : undefined
                    }
                    courierPosition={
                        lastCourierLocation ? {latitude: lastCourierLocation.latitude, longitude: lastCourierLocation.longitude} : undefined
                    }
                />
            ) : null}

            {job.fromLocation.point ? (
                <Marker icon={senderMarkerIcon} position={[job.fromLocation.point.latitude, job.fromLocation.point.longitude]} />
            ) : null}
            {job.toLocation.point ? (
                <Marker icon={receiverMarkerIcon} position={[job.toLocation.point.latitude, job.toLocation.point.longitude]} />
            ) : null}
            {job.courierLocations.length !== 0 && lastCourierLocation ? (
                <Marker icon={carMarkerIconDefault} position={[lastCourierLocation?.latitude, lastCourierLocation?.longitude]} />
            ) : null}
            <CustomTileLayer />
        </MapContainer>
    );
};

export default TrackingViewMap;
