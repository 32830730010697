import React, {useEffect, useState} from 'react';
import {ColorFormat, TimeProps} from 'react-countdown-circle-timer';
import {HourglassTimerIcon, JobStatus, Translation, timeInMinutes, timerTimeWithMinutes} from 'palipali-panel-common-web';
import {Box, Stack, Typography, useTheme} from '@mui/material';

export enum CircleTimerType {
    MINUTES = 'minutes',
    SECONDS = 'seconds',
    MINUTES_AND_SECONDS = 'minutesAndSeconds',
}

interface ITimerValueDisplayProps extends TimeProps {
    type: CircleTimerType;
    jobStatus?: JobStatus;
    countNegative: boolean;
    negativeElapsedTime?: number;
}
const TimerValueDisplay: React.FC<ITimerValueDisplayProps> = ({remainingTime, negativeElapsedTime, type, jobStatus, countNegative}) => {
    const theme = useTheme();
    const [negativeTime, setNegativeTime] = useState<number>(negativeElapsedTime && negativeElapsedTime < 0 ? -negativeElapsedTime : 0);
    const timerTime = countNegative ? negativeTime : remainingTime;
    const shouldDisplayMinutes =
        type === CircleTimerType.MINUTES || (type === CircleTimerType.MINUTES_AND_SECONDS && (timerTime > 300 || timerTime < -300));
    useEffect(() => {
        let timer: ReturnType<typeof setInterval>;
        if (countNegative) {
            timer = setInterval(() => {
                setNegativeTime((t) => t + 1);
            }, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [countNegative]);

    const timeDisplayedValue = () => {
        if (type === CircleTimerType.SECONDS) {
            return `${timerTimeWithMinutes(timerTime, countNegative)}`;
        }
        if (type === CircleTimerType.MINUTES_AND_SECONDS) {
            return `${
                timerTime > 300 || timerTime < -300
                    ? timeInMinutes(timerTime, countNegative)
                    : timerTimeWithMinutes(timerTime, countNegative)
            }`;
        }
        return `${timeInMinutes(timerTime, countNegative)}`;
    };

    if (jobStatus === JobStatus.SEARCHING) {
        return (
            <Stack className="progress-string-value-wrapper">
                <Typography variant="caption" component="div" color="primary">
                    <HourglassTimerIcon color={'primary'} />
                </Typography>
            </Stack>
        );
    }

    return (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} className={'progress-string-value-wrapper'}>
            <Typography
                className={'progress-string-value'}
                component={'span'}
                color={countNegative ? (theme.palette.error.main as ColorFormat) : 'text.primary'}>
                {timeDisplayedValue()}
            </Typography>
            {shouldDisplayMinutes && (
                <Typography
                    component={'span'}
                    color={countNegative ? (theme.palette.error.main as ColorFormat) : 'text.primary'}
                    className="progress-string-measurement-unit">
                    <Translation text={`jobInfoCard.timer.minutes`} />
                </Typography>
            )}
        </Box>
    );
};

export default TimerValueDisplay;
