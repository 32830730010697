import {createBrowserHistory} from 'history';
import {
    CommonRootState,
    ISystemParametersState,
    alertSlice,
    initLogout,
    loaderSlice,
    sagaSlice,
    systemParametersSlice,
} from 'palipali-panel-common-web';
import {CombinedState, combineReducers} from 'redux';
import {RouterState, createReduxHistoryContext} from 'redux-first-history';
import anonymousJobSlice, {AnonymousJobState} from './anonymousJobSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    alert: alertSlice,
    saga: sagaSlice,
    router: routerReducer,
    loader: loaderSlice,
    anonymousJob: anonymousJobSlice,
    systemParameters: systemParametersSlice,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};

export type RootState = CombinedState<{
    router: RouterState;
    systemParametersSlice: ISystemParametersState;
    anonymousJob: AnonymousJobState;
}> &
    CommonRootState;

export default rootReducer;
