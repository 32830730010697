import {useMap} from 'react-leaflet';
import React from 'react';
import L from 'leaflet';

interface ICreateJobSenderDataProps {
    latitude: number | undefined;
    longitude: number | undefined;
    additionalPosition?: {
        latitude: number;
        longitude: number;
    };
    courierPosition?: {
        latitude: number;
        longitude: number;
    };
    onCoordinatesSelected?: (latitude: number, longitude: number) => void;
}

const UpdateMapCenter: React.FC<ICreateJobSenderDataProps> = ({
    latitude,
    longitude,
    additionalPosition,
    courierPosition,
    onCoordinatesSelected,
}) => {
    const map = useMap();
    if (latitude && longitude) {
        map.panTo({lat: latitude, lng: longitude});
    }

    if (additionalPosition && latitude && longitude) {
        const position = L.latLng(latitude, longitude),
            additionalPositionCoordinates = L.latLng(additionalPosition.latitude, additionalPosition.longitude),
            bounds = L.latLngBounds(position, additionalPositionCoordinates);
        if (courierPosition) {
            const courierPositionCoordinates = L.latLng(courierPosition.latitude, courierPosition.longitude);
            bounds.extend(courierPositionCoordinates);
        }
        const paddingOptions: L.FitBoundsOptions = {padding: [30, 30]};
        map.fitBounds(bounds, paddingOptions);
    }

    if (onCoordinatesSelected) {
        map.on('click', function (e) {
            const coord = e.latlng,
                lat = coord.lat,
                lng = coord.lng;

            onCoordinatesSelected(lat, lng);
        });
    }

    return null;
};

export default UpdateMapCenter;
