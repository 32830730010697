import React from 'react';
import {Box, Theme, ThemeProvider} from '@mui/material';
import TrackingAppHeader from '../TrackingAppHeader';
import JobPlaceholderCard from '../JobPlaceholderCard';

interface IJobNotFoundHostProps {
    theme: Theme;
}

const JobNotFoundHost: React.FC<IJobNotFoundHostProps> = (props: IJobNotFoundHostProps) => {
    return (
        <ThemeProvider key="provider-wrapper" theme={props.theme}>
            <Box component="main" className="tracking-app-host">
                <Box className="tracking-app-content">
                    <TrackingAppHeader />
                    <JobPlaceholderCard labelKey={'noJob'} />
                </Box>
            </Box>
        </ThemeProvider>
    );
};
export default JobNotFoundHost;
