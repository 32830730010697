const en = {
    app: {
        title: 'Order tracking',
        courierTitle: 'Courier',
        companyTitle: 'PaliPali',
        errorPages: {
            401: {
                title: '401 - Unauthorised',
                description: 'You are not authorised to view this page.',
                buttons: {
                    backToHome: 'Back to home page',
                },
            },
            404: {
                title: '404 - Page not found',
                description: 'The page you are looking for does not exist.',
                buttons: {
                    backToHome: 'Back to home page',
                },
            },
            500: {
                title: '500 - Server error',
                description: 'Something has gone wrong. Try again later.',
                buttons: {
                    backToHome: 'Back to home page',
                },
            },
        },
        timeComponent: {
            estimatedTime: ' (expected time)',
            courierArriving: 'Courier will soon be on site.',
            processAt: 'The order will be published on:',
            timer: {
                hours: 'h.',
                minutes: 'min.',
            },
        },
        exception: {
            invalid_identifier: 'Specified identifier is invalid.',
        },
    },
    noJob: {
        title: 'Order number is incorrect',
        description: 'Order with specified id does not exist.',
    },
    jobInactive: {
        title: 'Order is no longer active',
        description: 'Order has been executed or cancelled.',
    },
    button: {
        visitPageLabel: {
            visit: 'Visit us at',
            www: 'www.palipali.pl',
        },
    },
    jobInfoCard: {
        sender: 'Employer',
        courier: 'Courier',
        sendingPoint: 'Point of dispatch',
        innerOrderNumber: 'Internal order number',
        additionalInfo: 'Additional information',
        estimatedTime: ' (expected time)',
        processAt: 'The order will be published on:',
        courierArriving: 'Courier will be soon on place.',
        timer: {
            hours: 'h.',
            minutes: 'min.',
        },
    },
    trackingView: {
        sender: 'Employer',
        courier: 'Courier',
        sendingPoint: 'Point of dispatch',
        innerOrderNumber: 'Internal order number',
        additionalInfo: 'Additional information',
        estimatedTime: ' (expected time)',
        timer: {
            hours: 'h.',
            minutes: 'min.',
        },
    },
    teams: {
        teamView: {
            teamDetails: {
                street: 'ul.',
            },
        },
    },
    footer: {
        copyright: `PaliPali {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: '404 - page not found',
    },
    alerts: {
        warning: 'Warning!',
        error: 'Error!',
        success: 'Success!',
        info: 'Information',
        tryAgain: 'Try again',
        unknown: 'Unknown error has occurred.',
        accessDenied: 'Access denied',
        notFound: 'Order with the specified id does not exist.',
        authError: 'User name or password is incorrect.',
        noAccessError: 'You do not have permission to view this page.',
        baseError: 'Error has occurred, please contact your administrator.',
        loginError: 'There was an error logging in, try again.',
        accountNotConfirmed: 'Account is not confirmed.',
        accountNotActive: 'Account is not active.',
        accountDeleted: 'Account was deleted.',
        accountIsInvitation: 'Incorrect data.',
    },
    month: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
    },
    weekday: {
        sunday: 'Sun',
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
    },
    jobStatusDetails: {
        planned: 'Scheduled',
        searching: 'Searching',
        drive: 'On the road',
        pickup: 'Taking delivery',
        delivery: 'Delivery of the consignment',
        delivered: 'Realised',
        cancelledPurchaser: 'Cancelled',
        cancelledCourier: 'Cancelled by Courier',
        problemPurchaser: 'Reported',
        problemCourier: 'Reported by Courier',
        deliveryDrive: 'In delivering',
        failed: 'Failure',
    },
};

export default en;
