import {Box} from '@mui/material';
import React from 'react';

const TrackingAppHeader: React.FC = () => {
    return (
        <Box className="tracking-app-logo">
            <img src={require('../../assets/images/palipali-full-logo.png')} alt="PaliPali logo" />
        </Box>
    );
};

export default TrackingAppHeader;
